<template>
    <div class="">
        <!-- Heading -->
        <h3 class="text-h5">
            Set satellite image metadata
        </h3>

        <!-- Subheading -->
        <p class="grey--text">
            Get satellite image for the selected date and set its metadata
        </p>

        <!-- Form Data -->
        <v-form
            ref="form"
            v-model="isFormValid"
        >
            <v-container fluid>
                <v-row class="d-flex justify-space-between py-5">
                    <v-col cols="12" md="5" class="pr-8">
                        <v-row>
                            <v-col>
                                <!-- Date Dropdown -->
                                <v-select
                                    outlined
                                    v-model="selectedDate"
                                    label="Select a date *"
                                    :items="dateList"
                                    color="primary"
                                    @input="handleDateDropdownChange"
                                    :disabled="getSatelliteImageStatus_Pending"
                                    required
                                    hide-details
                                    :menu-props="{ closeOnContentClick: true }"
                                >
                                    <template #append>
                                        <v-icon 
                                            v-if="polygonData[selectedDate].isSaved" 
                                            color="primary">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                        <v-icon>mdi-chevron-down</v-icon>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex align-center">
                                            {{ item }}
                                            <v-chip
                                                v-if="polygonData[item].isSaved"
                                                small
                                                class="ml-5"
                                                color="primary"
                                            >
                                                Saved
                                            </v-chip>
                                        </div>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-select
                                    outlined
                                    v-model="polygonData[selectedDate].waterLevel"
                                    label="Hydrological condition *"
                                    :items=waterLevels
                                    item-title="text" 
                                    color="primary"
                                    :disabled="!selectedDate || getSatelliteImageStatus_Pending"
                                    persistent-hint
                                    required
                                    :rules="waterLevelRules"

                                >
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex align-center">
                                            {{ item.text }} {{  item.description && "-"}} {{  item.description }}
                                        </div>
                                    </template>
                                </v-select>
                                <v-textarea
                                    hide-details
                                    outlined
                                    v-model="polygonData[selectedDate].notes"
                                    label="Notes"
                                    color="primary"
                                    rows="2"
                                    :disabled="!selectedDate || getSatelliteImageStatus_Pending"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <ImageInput
                                    :selectedDate="selectedDate"
                                    :existingImage="polygonData[selectedDate].satelliteImage.ground_truth_image || null"
                                    @image-updated="groundTruthToUpload = $event"
                                /> 
                            </v-col>
                        </v-row>

                        <MetadataInfo class="my-6" :info="otherMetadata || info"></MetadataInfo>

                        <v-row>
                            <div class="d-flex">
                                <v-btn
                                    v-if="!polygonData[selectedDate].isSaved"
                                    color="primary"
                                    class="white--text text-capitalize"
                                    :loading="setMetadataStatus_Pending"
                                    :disabled="!isFormValid"
                                    @click="setMetadata"
                                >
                                    Save
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="primary"
                                    class="white--text text-capitalize"
                                    :loading="setMetadataStatus_Pending"
                                    :disabled="!isFormValid"
                                    @click="setMetadata"
                                >
                                    Update
                                </v-btn>

                                <v-slide-x-transition>
                                    <div
                                        v-if="polygonData[selectedDate].isSaved && showNotification"
                                        class="success--text ml-5 d-flex align-center"
                                    >
                                        Successfully Saved
                                        <v-icon class="success--text ml-2">
                                            mdi-checkbox-marked-circle
                                        </v-icon>
                                    </div>
                                    
                                    <div
                                        v-if="setMetadataStatus_Error"
                                        class="error--text ml-5 d-flex align-center"
                                    >
                                        Error: {{ errorMessage }}
                                        <v-icon class="error--text ml-2">
                                            mdi-close-circle
                                        </v-icon>
                                    </div>
                                </v-slide-x-transition>
                            </div>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="7" class="pl-8">
                        <v-row>
                            <v-col>
                                <div class="d-flex justify-space-between align-center mb-4">
                                    <v-radio-group                                   
                                        class="text-capitalize ma-0"
                                        v-model="image_type"
                                        hide-details
                                        row
                                        :disabled="getSatelliteImageStatus_Pending || !selectedDate"
                                    >
                                        <v-radio
                                            label="False Color / (B11-B8-B4)"
                                            :value="false"
                                        ></v-radio>
                                        <v-radio                                        
                                            label="True Color / (B4-B3-B2)"
                                            :value="true"
                                        ></v-radio>                                        
                                    </v-radio-group>
                                </div>
                            </v-col>
                        </v-row>

                        <!-- Preview The Satellite Image -->
                        <SatelliteImgPreview
                            :imgSrc="sataImgURLToShow"
                            :is-loading="getSatelliteImageStatus_Pending"
                            :disable-previous="disableLoadPrevious"
                            :disable-next="disableLoadNext"
                            @load-previous-image="loadPrevImage()"
                            @load-next-image="loadNextImage()"
                        />
                        <v-row v-if="error">
                            <v-col>
                                <ErrorAlert
                                    :error="error"
                                    :error-message="errorMessage"
                                    @error-closed="error=false"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-form>        
    </div>
</template>

<script>
    import { withAsync } from "@/helpers/withAsync";
    import { apiStatus } from "@/api/constants/apiStatus";
    import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
    import { postMetadata, postGroundTruthImage } from "@/api/metadataApi.js";
    import { mapGetters } from "vuex";
    import ImageInput from "@/components/metadataModal/ImageInput.vue";
    import GetSatelliteImg from "@/components/metadataModal/GetSatelliteImg.vue";
    import SatelliteImgPreview from "@/components/metadataModal/SatelliteImgPreview.vue";
    import { setSelectedDate, updateClassificationMetadata } from "@/api/polygonApi.js";
    import { CONFIG } from "@/common/config";
    import ErrorAlert from "@/components/ErrorAlert.vue";
    import MetadataInfo from "@/components/metadataModal/MetadataInfo";

export default {
    name: "MetadataForm",
    components: { ImageInput, GetSatelliteImg, SatelliteImgPreview, ErrorAlert, MetadataInfo },

    props: {
        polygonId: {
            type: Number,
            default: null
        },
        preSelectedDate: {
            type: String,
            required: false,
            default: "",
            required: true,
        },
        dateList: {
            type: Array,
            default: [],
            required: true,
        },
        preLoadedPolygonData: {
            type: Object,
            default: null
        }, 
        
        otherMetadata: {
            type: Object,
            default: null
        }
    },

    data() {
        return {
            error: false,
            isFormValid: null,
            apiStatus,
            errorMessage: '',
            getSatelliteImageStatus: apiStatus.Idle,
            setMetadataStatus: apiStatus.Idle,
            showNotification: false,
            selectedDate: this.preSelectedDate,
            polygonData: {}, // holds all polygon data => date: { form_data_for_this_date }, etc...
            image_type: false,
            groundTruthToUpload: null,
            waterLevels: [
               { text: "Flowing", description: "visible continuous flow of water along the reach"},
               { text: "Ponding", description: "discontinuous water presence, with isolated ponds along the reach" },
               { text: "Dry", description: "absence of surface water" },
               { text: "Cloudy"}
            ],
            waterLevelRules: [ v => !!v || 'Name is required'],
        };
    },

    mounted() {
        (async () => {
            await this.handleDateDropdownChange();
        })();
    },

    created() {
        if( this.hasNoAvailableDates ) return;

        if (this.preLoadedPolygonData) {
            this.dateList.forEach(date => {
                const {metadata, image_id, fci_url, tci_url, ground_truth_images, generated_at, created_at } = this.preLoadedPolygonData.images.find(img => img.generated_at === date) || {};

                // Note: backend implemented storing multiple ground truth images. We only need one. The most recent is the last one.
                this.$set(this.polygonData, date, {
                    waterLevel: metadata?.hydrological_condition || null,
                    notes: metadata?.notes || "",
                    satelliteImage: {
                        tci_url: tci_url || null,
                        fci_url: fci_url || null,
                        ground_truth_image: !!ground_truth_images?.length ? ground_truth_images.at(-1).url : null,
                        image_id,
                        generated_at,    
                        created_at
                    },
                    metadataId: metadata?.image_metadata_id || null,
                    isSaved: !!(metadata && metadata.hydrological_condition),
                })
            })
        } else {
            this.dateList.forEach(date => {
            this.$set(this.polygonData, date, {
                waterLevel: null,
                notes: "",
                satelliteImage: {
                    created_at: null,
                    tci_url: null,
                    fci_url: null,
                    ground_truth_image: null,
                    image_id: null,
                    generated_at: null,
                },
                metadataId: null,
                isSaved: false,
            })
        })
        }
    },

    computed: {
        ...apiStatusComputed("setMetadataStatus"),
        ...apiStatusComputed("getSatelliteImageStatus"),
        ...mapGetters([
            "getSavedDates", 
            "getPolygonId", 
        ]),

        info() {
            return {
                user_name: this.preLoadedPolygonData?.user.name,
                river_name: this.preLoadedPolygonData?.river.name,
                created_at: this.polygonData[this.selectedDate].satelliteImage.created_at,
                polygon_name: this.preLoadedPolygonData?.name,
                polygon_notes: this.preLoadedPolygonData?.notes
            }
        },

        sataImgURLToShow() {
            if (!this.selectedDate) return null;

            const tci_url = this.polygonData[this.selectedDate].satelliteImage?.tci_url;
            const fci_url = this.polygonData[this.selectedDate].satelliteImage?.fci_url;
            
            return this.image_type ? CONFIG.image_api.concat(tci_url) : CONFIG.image_api.concat(fci_url);
        },

        disableLoadPrevious() {
            return this.hasNoAvailableDates || this.noSelectedImage || this.isSelectedDateFirst || this.setMetadataStatus_Pending;
        },

        disableLoadNext() {
            return this.hasNoAvailableDates || this.noSelectedImage || this.isSelectedDateLast || this.setMetadataStatus_Pending;
        },

        hasNoAvailableDates() {
            return this.dateList.length === 0;
        },

        hasSatelliteImage() {
            let satelliteImage_tmp = this.polygonData[this.selectedDate].satelliteImage; // todo assign here  this.polygonData[this.selectedDate].satelliteImage and then check if null
            return this.selectedDate && satelliteImage_tmp && (satelliteImage_tmp?.tci_url || satelliteImage_tmp.satelliteImage?.fci_url)
        },

        noSelectedImage() {
            return !this.selectedDate;
        },

        isSelectedDateFirst() {
            return this.selectedDate === this.dateList[0] ||  this.dateList.indexOf( this.selectedDate ) === 0;
        },
        
        isSelectedDateLast() {
            return this.selectedDate === this.dateList[this.dateList.length - 1] || this.dateList.indexOf( this.selectedDate ) === this.dateList.length - 1;
        },

    },

    methods: {
        // Handles the loading of the previous image in the date list.
        async loadPrevImage() {
            this.selectedDate = this.dateList[ this.dateList.indexOf( this.selectedDate ) - 1 ];
            await this.handleDateDropdownChange();
        },
       
        // Handes the loading of the next image in the date list.
        async loadNextImage() {
            this.selectedDate = this.dateList[ this.dateList.indexOf( this.selectedDate ) + 1 ];
            await this.handleDateDropdownChange();
        },

        async handleDateDropdownChange() {
            if (!this.hasSatelliteImage) {
                await this.getSatelliteImage( this.selectedDate );
            }
            this.$emit('date-changed', this.selectedDate);
        },

        // Get the satellite image for the selected date.
        async getSatelliteImage( selectedDate ) {
            this.getSatelliteImageStatus = apiStatus.Pending;

            const payload = { image_date: selectedDate };

            const { response, error } = await withAsync(
                setSelectedDate,
                this.polygonId,
                payload
            );

            if ( error ) {
                this.getSatelliteImageStatus = apiStatus.Error;
                this.error = true;
                this.errorMessage = "The was an error while getting the satellite image.";
                this.$emit("on-error");
                return;
            }

            this.getSatelliteImageStatus = apiStatus.Success;
            // Assign response to polygon data, base on our object structure
            const {fci_url, tci_url, ground_truth_images, image_id, generated_at, created_at} = response.data.data;
            this.polygonData[selectedDate].satelliteImage = {
                tci_url,
                fci_url,
                ground_truth_image: null,
                image_id,
                generated_at,
                created_at
            }
            this.$emit("image-generated", this.polygonData[selectedDate].satelliteImage.created_at)
        },


        // Send metadata to backEnd
        async setMetadata() {
            this.setMetadataStatus = apiStatus.Pending;
            const isFile = (file) => typeof File!== 'undefined' && file instanceof File
            try {

                // Post groundtruth image
                if (this.groundTruthToUpload && 
                    isFile(this.groundTruthToUpload)) {
                    await this.postGroundTruthImage();
                }

                // Set other metadata
                if (!this.polygonData[this.selectedDate].isSaved) {
                    // Save metadata(first time)
                    await this.postMetadata();
                } else {
                    // Update metadata
                    await this.patchMetadata();
                }
                this.setMetadataStatus = apiStatus.Success
                this.showNotification = true;
                setTimeout(() => {
                    this.showNotification = false;
                }, 1500);

                this.polygonData[this.selectedDate] = { ...this.polygonData[this.selectedDate], isSaved: true };

                // todo is this emit neeeded?
                this.$emit("form-submitted");
            } catch (error) {
                this.setMetadataStatus = apiStatus.Error;
                this.errorMessage = error;
            }
        },

        async postMetadata() {
            const payload = {
                hydrological_condition: this.polygonData[this.selectedDate].waterLevel,
                notes: this.polygonData[this.selectedDate].notes || undefined,
                image_id: this.polygonData[this.selectedDate].satelliteImage.image_id,
            };

            const { response, error } = await withAsync(
                postMetadata,
                payload
            );

            if (error) {
                throw new Error(error.response.data.message);
            }

            this.polygonData[this.selectedDate].metadataId = response.data.data.image_metadata_id; 
        },

        async patchMetadata() {
            const payload = {
                hydrological_condition: this.polygonData[this.selectedDate].waterLevel,
                notes: this.polygonData[this.selectedDate].notes || undefined,
                metadata_id: this.polygonData[this.selectedDate].metadataId
            };

            const { response, error } = await withAsync(
                updateClassificationMetadata,
                payload
            );

            if (error) {
                throw new Error(error.response.data.message);
            }
        },

        async postGroundTruthImage() {
            const polygonData = {
                image_date: this.selectedDate,
                "images[]": this.groundTruthToUpload
            }

            const { response, error } = await withAsync(
                postGroundTruthImage,
                this.polygonId,
                polygonData
            );

            if (error) {
                throw new Error(`There was an problem uploading image for polygon with id: ${this.polygonId}. Error: ${error}`);
            }

            this.polygonData[this.selectedDate].satelliteImage.ground_truth_image = this.groundTruthToUpload;
        },

        reset() {
            this.selectedDate = "";
        },
    },
}
</script>